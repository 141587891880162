@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Noto Sans Georgian', sans-serif;
}

header{
    width: 100%;
    height: 81px;
    background-color: #01619b;
}

body{
    background-color: #f0f9fd;
}

.boxCnt{
    max-width: 1920px;
    width: 100%;
    padding: 70px 20px;
    margin: auto;
    display: flex;
    justify-content: center;
}

.boxWrap{
    display: flex;
    justify-content: space-between;
    max-width: 1000px;
    flex-wrap: wrap;
    margin: auto;
}

.boxWrap__div{
    overflow: hidden;
    border-radius: 29px;
}

.box{
    width: 300px;
    height: 167px;
    transition: .5s;
    border: 3px solid;
    text-align: center;
    padding: 10px;
    font-size: 18px;
    display: flex;
    align-items: center;
    font-weight: bold;
    border-radius: 29px;
    margin-bottom: 30px;
    cursor: pointer;
    margin-right: 10px;
    text-decoration: none;
    color: #fff;
    background-color: #01619b;
    box-shadow: #2285b2 2px 2px 9px;
    border-color: #0071cc;
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
}

.box.loaded{
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}

.box:hover{
    background: #fff;
    border-color: rgb(118 194 255) !important;
    color: #00558a !important;
    box-shadow: #2285b2 2px 2px 9px;
}

.ibCnt{
    max-width: 1280px;
    margin: auto;
    padding: 40px 20px;
}

.ib__center{
    display: flex;
    justify-content: center;
}

.ib__center.column{
    flex-direction: column;
    width: -webkit-max-content;
    width: max-content;
    margin: auto;
    align-items: center;
}

.ib__center.ethical{
    font-size: 18px;
    color: #fff;
}

.DisciplineBox{
    min-width: 310px;
    height: 230px;
    border-radius: 0px 30px 30px 30px;
    background-color: #01619b;
    text-align: center;
    font-size: 20px;
    padding: 0 15px;
    flex-grow: 1;
}

.DisciplineBox.ethical{
    height: 200px;
}

.DisciplineBox__title{
    padding-top: 10px;
    padding-bottom: 5px;
}

.DisciplineBox__precent{
    padding: 10px 0;
    border-bottom: 1px solid #da9e90;
    margin-bottom: 25px;
}

.grades__footerCnt{
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    position: relative;
    justify-content: center;
}

.grades__footerCnt_poa1{
    position: absolute;
    font-size: 10px;
    bottom: 77px;
    left: 67px;
    text-align: center;
    width: 70px;
}

.grades__footerCnt_poa2{
    position: absolute;
    font-size: 10px;
    bottom: 77px;
    left: 145px;
    text-align: center;
    width: 77px;
}

.grade__data__api{
    width: 48px;
    height: 48px;
    line-height: 48px;
    background: #fcb683;
    border-radius: 6px;
    margin-top: 7px;
}

.grade__data__api.ethical{
    background-color: #fff;
    color: #000;
}

.termEstCnt{
    display: flex;
    flex-wrap: wrap;
    grid-gap: 30px;
    gap: 30px;
    margin-top: 15px;
    align-items: center;
    justify-content: center;
}

.avgCnt{
    width: 310px;
    background-color: #16987c;
    height: 90px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    border-radius: 0px 15px 15px 15px;
    font-size: 20px;
    margin-top: 20px;
}


.avgCnt .grade__data__api{
    margin-right: 10px;
    background-color: #aadcd0;
    text-align: center;
}

.ibChart{
    margin-top: 30px;
}

.ibChart .recharts-layer.recharts-cartesian-axis-tick{
    cursor: pointer;
}

.headerCnt{
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 10px;
    justify-content: space-between;
}

.headerCnt__aTag{
    margin-top: 10px;
    text-decoration: none;
}

.headerCnt__aTag div{
    color: #fff;
}

.headerCnt__arrow{
    margin-left: 10px;
    font-size: 40px !important;
    color: #fff;
}


/* .MuiTableCell-root{
    border: 1px solid #01619b !important;
} */
.css-177gid-MuiTableCell-root{
    border: 1px solid #01619b !important;
    padding: 0px !important;
}
.css-1ex1afd-MuiTableCell-root{
    border-bottom: 1px solid #01619b !important;
    border-left: 1px solid #01619b !important;
}
.css-15wwp11-MuiTableHead-root{
    display: table-header-group;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #01619b;
    z-index: 22;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root{
    max-height: 500px;
}

.css-1ndpvdd-MuiTableCell-root{
    color: #fff !important;
    border-left: 1px solid #01619b;
}

.ethicCnt{
    margin: 30px 60px;
}

.ethicCnt .css-1ex1afd-MuiTableCell-root{
    white-space: nowrap;
}

.ethicCnt .css-1ndpvdd-MuiTableCell-root div{
    white-space: nowrap;
}

.ethicCnt .css-1ygcj2i-MuiTableCell-root{
    padding: 10px;
}

.ethicCnt__w{
    width: 90px;
    border: unset !important;
    border-right: 1px solid #01619b !important;
}

.ethicCnt__w.week{
    color: #fff !important;
    white-space: nowrap;
}

.ethicCnt__w.v2{
    width: 90px;
    border: unset !important;
    border-right: unset !important;
}

.ethical__precent{
    padding: 10px 0;
    border-bottom: 1px solid #ffffff;
    margin-bottom: 15px;
}

.ethical__title{
    padding-top: 10px;
    padding-bottom: 5px;
    color: #fff;
}

.modified__title{

    color: #fff;
    font-size: 30px;
}

/* shefaseba table */

.shefasebaCnt{
    max-width: 100vw;
    padding-bottom: 0;
    overflow-x: auto;
    margin: 50px 20px;
}

.shefasebaTable{
    border-collapse: collapse;
    margin: auto;
}

.shefasebaTable__col{
    border: 1px solid #000;
    text-align: center;
    padding: 8px;
    background-color: #fff;
}

.shefasebaTable__col.row{
    background-color: #01619b !important;
    color: #fff !important;
}

/* Custom Table absence */

.absenceMain{
    max-width: 1280px;
    margin: 50px auto;
}

.absenceMain .css-gg4vpm{
    justify-content: center;
}

.absenceMain__cnt{
    overflow-x:scroll;
    max-width:1535px;
    margin: 50px auto;
    padding: 0 5px;
}

.absenceTable{
    width: 100%;
    padding: 5px 10px;
    overflow-y: scroll;
    border-collapse: collapse;
}

.absenceTable__date{
    border: 1px solid #000;
    min-width: 30px;
    max-width: 42px;
    height: 78px;
    padding-top: 24px;
    white-space: nowrap;
}

.absenceTable__attend{
    background-color: #fff;
    text-align: center;
}

.absenceTable__date div{
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.absenceTable__nameSurname{
    min-width: 230px;
}
.absenceTable tr{
    border: 1px solid #000;
}
.absenceTable td{
    border: 1px solid #000;
}
.absenceTable th{
    border: 1px solid #000;
}

.absenceMain .recharts-wrapper{
    margin: auto;
}
.absenceDropdown {
    display: flex;
}

.absenceDropdown label[data-shrink=false]+.MuiInputBase-formControl .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    background-color: #fff !important;
}


.aside_aTag{
    text-decoration: none;
}

.aside_aTag.active div{
    background-color: #01619b !important;
    color: #fff !important;
}

.disciplineAside{
    z-index: 2;
}

.disciplineAside .css-bshv44-MuiButtonBase-root-MuiListItem-root{
    padding: 18px 16px;
    color: #000;
}

.aside_aTag.home{
    margin-bottom: 5px;
    border-bottom: 1px solid #cecece;
    display: none;
}

.aside_aTag.home div{
    color: #01619b;
    justify-content: center;
}

.disciplineAside .aside_aTag.home:hover div{
    color: #fff;
}

.css-zxdg2z{
    padding: unset !important;
}

.disciplineAside .css-bshv44-MuiButtonBase-root-MuiListItem-root:hover{
    color: #fff;
    background-color: rgb(1 97 155);
}

.disciplineAside .css-h4y409-MuiList-root{
    padding-top: 0;
}

.disciplineAside .css-1i5tyl5-MuiDrawer-docked{
    position: relative;
}

.disciplineAside .css-12i7wg6-MuiPaper-root-MuiDrawer-paper{
    position: absolute;
    top: unset;
    height: calc(120vh - 80px) !important;
    background-color: #fff;
    border: none;
    box-shadow: 4px 0 5px 0px #888;
}

.disciplineAside.open{
    -webkit-transform: translateX(0);
            transform: translateX(0);
}


.pageName{
    font-size: 20px;
    font-weight: bold;
    margin: 15px 0;
    text-align: center;
}

/* dropdown */

.yearDropwdown{
    width: 100%;
    display: flex;
    justify-content: center;
}

.yearDropwdown .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
    background-color: #fff;
    width: 290px;
}

.login__wrapper{
    min-height: calc(100vh - 81px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-image: url(/static/media/loginBckg.a48e9e1c.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.headerLogoImg{
    background-image: url(/static/media/logoMtiebi.4997247b.png);
    background-repeat: no-repeat;
    width: 120px;
    height: 44px;
    margin-left: 20px;
    display: block;
}

/*.loginCnt{*/
/*    border: 1px solid #03b4f7;*/
/*    max-width: 600px;*/
/*    width: 100%;*/
/*    background-color: #fff;*/
/*    min-height: 380px;*/
/*    margin: auto;*/
/*    border-radius: 25px;*/
/*    box-shadow: rgb(89 158 191) 0px 5px 30px;*/
/*}*/

/*.login__label{*/
/*    display: inline-block;*/
/*    padding-bottom: 5px;*/
/*    color: #007db5;*/
/*    font-weight: bold;*/
/*}*/

/*.login__inputCnt{*/
/*    margin: auto;*/
/*    text-align: left;*/
/*    margin-bottom: 20px;*/
/*}*/

/*.formCnt{*/
/*    width: max-content;*/
/*    margin: auto;*/
/*}*/

/*.formCnt__input{*/
/*    padding: 8px 5px;*/
/*    border-radius: 7px;*/
/*    border: 1px solid #bbbbbb;*/
/*    width: 100%;*/
/*    min-width: 280px;*/
/*}*/

/*.formCnt__input:focus{*/
/*    outline: none;*/
/*    border: 1px solid #000;*/
/*}*/

/*.formCnt__btnCnt .css-sghohy-MuiButtonBase-root-MuiButton-root{*/
/*    width: 50%;*/
/*    font-weight: bold;*/
/*    background: #01619b;*/
/*    border-radius: 18px;*/
/*    min-width: 185px;*/
/*    padding: 8px;*/
/*    font-size: 15px;*/
/*}*/

/*.formCnt__title{*/
/*    margin: 25px 0;*/
/*    padding-bottom: 15px;*/
/*    font-size: 28px;*/
/*    letter-spacing: 1px;*/
/*    display: inline-block;*/
/*    border-bottom: 2px solid #2196F3;*/
/*    width: 100%;*/
/*    font-weight: bold;*/
/*}*/

/*.remember__password a{*/
/*    margin-top: 8px;*/
/*    display: block;*/
/*    font-size: 14px;*/
/*    color: #03A9F4;*/
/*}*/

@media only screen and (max-width: 1240px) {
    .ibChart{
        overflow-x: scroll;
    }
}

@media only screen and (max-width: 970px) {
    .boxWrap{
        max-width: 635px;
    }
}

@media only screen and (max-width: 660px){
    .boxWrap{
        max-width: 300px;
    }
}

/*.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-padding.MuiListItem-button.css-bshv44-MuiButtonBase-root-MuiListItem-root{*/
/*    height: 80px;*/
/*    background-color: red;*/
/*#f0f9fd*/
/*}*/



/*@media only screen and (max-width: 450px){*/
/*    .boxWrap{*/
/*        max-width: 300px;*/
/*    }*/
/*    .formCnt__input{*/
/*        min-width: unset;*/
/*    }*/
/*    .formCnt__title{*/
/*        font-size: 25px;*/
/*    }*/
/*}*/

/*@media only screen and (max-width: 320px){*/
/*    .formCnt{*/
/*        width: unset;*/
/*        padding: 0 15px;*/
/*    }*/
/*}*/
.MuiDataGrid-columnHeaderTitle {
    height: 100px; /* Adjust the height value as per your requirement */
}

.MuiDataGrid-columnHeaderTitleContainer {
    height: 100px; /* Adjust the height value as per your requirement */
}

.MuiDataGrid-columnHeaders {
    height: 100px; /* Adjust the height value as per your requirement */
}

.MuiDataGrid-columnHeader {
    height: 100px; /* Adjust the height value as per your requirement */
}

div.MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor {
    max-height: 100px !important;
}

.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor.header-class {
    height: 100px !important;
    min-width: 85px !important;
    max-width: 85px !important;
}

.cell-header.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textCenter.MuiDataGrid-withBorderColor {
    min-width: 85px !important;
    max-width: 85px !important;
}

.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: url(/static/media/mtiebi2.d177b2c5.jpg) no-repeat center center fixed;
    background-size: cover;
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
}

.login-container {
    position: relative;
    width: 300px;
    height: 350px;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #333333; /* Update label and input text color */
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.3); /* Add box-shadow */
}
.login-container h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
}

.login-container form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.login-container form .form-group {
    margin-bottom: 20px;
}

.login-container form label {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
}

.login-container form input[type="text"],
.login-container form input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-top: 5px ;
    border: none;
    border-radius: 3px;
    background: rgba(231, 229, 229, 0.8);
    color: #0a0808; /* Update text color */
    font-size: 14px;
}

.login-container form input[type="text"]::-webkit-input-placeholder, .login-container form input[type="password"]::-webkit-input-placeholder {
    color: #999999; /* Update placeholder text color */
}

.login-container form input[type="text"]::placeholder,
.login-container form input[type="password"]::placeholder {
    color: #999999; /* Update placeholder text color */
}

.login-container form input[type="text"]:focus,
.login-container form input[type="password"]:focus {
    outline: none;
    background: rgb(222, 222, 222); /* Update background color on focus */
}

.login-container form button[type="submit"] {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 3px;
    background: #0e7fb7;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.3s;
}

.login-container form button[type="submit"]:hover {
    background: #075675;
}

.login-page form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.login-page form .form-group {
    margin-bottom: 20px;
}

/*.login-page form label {*/
/*    font-size: 14px;*/
/*    font-weight: 400;*/
/*}*/

/*.login-page form input[type="text"],*/
/*.login-page form input[type="password"] {*/
/*    width: 100%;*/
/*    padding: 10px;*/
/*    border: none;*/
/*    border-radius: 3px;*/
/*    background: rgba(255, 255, 255, 0.8);*/
/*    color: #333;*/
/*    font-size: 14px;*/
/*}*/

/*.login-page form input[type="text"]::placeholder,*/
/*.login-page form input[type="password"]::placeholder {*/
/*    color: #999;*/
/*}*/

/*.login-page form button[type="submit"] {*/
/*    width: 100%;*/
/*    padding: 10px;*/
/*    border: none;*/
/*    border-radius: 3px;*/
/*    background: #0e7fb7;*/
/*    color: #ffffff;*/
/*    font-size: 16px;*/
/*    font-weight: 500;*/
/*    cursor: pointer;*/
/*    transition: background 0.3s;*/
/*}*/

/*.login-page form button[type="submit"]:hover {*/
/*    background: #075675;*/
/*}*/


/* Giorgi CSS */

.loginCnt{
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
    padding: 50px;
}

.loginCnt__img img{
    max-width: 200px;
}

.loginCnt__circleWrap{
    max-width: 500px;
    width: 100%;
    margin: auto;
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    left: 50%;
    top: calc(50px + 9vh);
}

.loginCnt__circle {
    max-width: 400px;
    height: 350px;
    border-radius: 50%;
    position: absolute;
    width: 100%;
}

.loginCnt__circle.img{
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: 0;
    background-image: url(/static/media/loginImg.5a8da0ed.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 3;
}

.loginCnt__circle.blue{
    left: 0;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: 225px;
    background-color: #01619b;
    z-index: 2;
}

.loginCnt__circle.gray{
    left: 0px;
    background-color: #f0f2f1;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: 80px;
    z-index: 1;
}

.loginCnt__circle.darkGray{
    left: 64%;
    background-color: #a2a2a2;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: 100px;
    z-index: 1;
}

.loginCnt__circle.whiteOpacity{
    left: 63%;
    background-color: #fdfdfd;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: 152px;
    z-index: 4;
    opacity: 0.5;
}

.loginCnt__circle.purple{
    left: 53%;
    top: 25px;
    background-color: #4c75bd;
    z-index: 5;
    opacity: 0.7;
}

.loginCnt__footer {
    color: #1a3658;
    font-size: 28px;
    position: absolute;
    left: 410px;
    width: 305px;
    text-align: center;
    top: 515px;
}

.formCnt{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 210px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.formCnt__title{
    color: #fff;
    font-size: 30px;
    letter-spacing: 2px;
    margin-top: 80px;
    padding: 15px 0;
}

.formCnt__input{
    width: 100%;
    padding: 8px 5px;
    border-radius: 12px;
    border: none;
    margin-bottom: 15px;
}

.formCnt__btnCnt{
    width: 100%;
    display: flex;
    justify-content: center;
}

.formCnt__btnCnt button{
    padding: 10px 30px;
    background: #6597ef;
    color: #fff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
}
.diagonal-lines {
    background: linear-gradient(45deg, transparent 25%, rgba(123, 181, 236, 0.29) 25%, rgba(123, 181, 236, 0.29) 50%, transparent 50%, transparent 75%, rgba(123, 181, 236, 0.29) 75%, rgba(123, 181, 236, 0.29));
    background-size: 7px 7px; /* Adjust the size of the lines */
    min-height: 100vh;
    max-height: 300vh;
}
